import React, { Component } from 'react'

import PermissionsChecker from '@shared/PermissionsChecker'
import Receipt from '@components/Receipt'
import RolesChecker from '@shared/RolesChecker'
import ConfirmDialog from '@shared/ConfirmDialog'
import TrackingTimeline from '../TrackingTimeline'

class CardSubOrder extends Component {
  state = {
    opened: false,
  }

  _printHandler() {
    let { sub_order, buyer_name, status, openModal, closeModal } = this.props

    openModal(
      <Receipt
        sub_order={sub_order}
        buyer_name={buyer_name}
        closeModal={closeModal}
        status={status}
        title="Comprovante de retirada"
      />,
    )
  }

  // _confirmCancel() {
  //   let { sub_order, buyer_name, status, openModal, closeModal, cancelSubOrder } = this.props

  //   openModal(
  //     <ConfirmDialog
  //       text="Tem certeza que deseja cancelar esse pedido?"
  //       ok={() => {
  //         cancelSubOrder(sub_order.id)
  //         closeModal()
  //       }}
  //       cancel={() => closeModal()}
  //     />,
  //   )
  // }



  _isServiceLine(sub_order) {
    const exp = /SER/gm
    if (sub_order.business_line.match(exp)) {
      return true
    }
    return false
  }

  _formatOldTrackingLink(tracking_link) {
    
    if(!tracking_link) return null

    let loggiLink = tracking_link
    loggiLink = loggiLink.replace("https://www.loggi.com/rastreador/", "");
    let   params = loggiLink.split('/')
    if (params.length === 2) {
      return 'https://www.loggi.com/rastreador/' + params[1]
    }{
      return tracking_link
    }
  }

  _isDigitalLine(sub_order) {
    const exp = /DIG/gm
    if (sub_order.business_line.match(exp)) {
      return true
    }
    return false
  }

  _getOrderDeliveryDestination() {
    const { order, sub_order } = this.props
    if (sub_order.school_is_b2c) {
      let address = ''
      let address_ = ''
      if (order.buyer_address_zipcode_delivery) {
        address = `${order.buyer_address_street_delivery}, ${order.buyer_address_number_delivery}${order.buyer_secondary_address_number_delivery ? ', ' + order.buyer_secondary_address_number_delivery : ''
          } - ${order.buyer_address_neighborhood_delivery}`
        address_ = `${order.buyer_address_city_delivery} - ${order.buyer_address_state_delivery}, ${order.buyer_address_zipcode_delivery}`
      } else {
        address = `${order.buyer_address_street}, ${order.buyer_address_number}${order.buyer_secondary_address_number ? ', ' + order.buyer_secondary_address_number : ''
          } - ${order.buyer_address_neighborhood}`
        address_ = `${order.buyer_address_city} - ${order.buyer_address_state}, ${order.buyer_address_zipcode}`
      }
      return (
        <React.Fragment>
          {address}
          <br />
          {address_}
        </React.Fragment>
      )
    }
    return 'Os livros serão entregues na escola'
  }
  render() {
    const { sub_order, status, children, cancelSubOrder, order} = this.props
    let show = this.state.opened ? 'less' : 'more'
    return (
      <>
        <div className='card-suborder'>
          <div className='card-suborder__header'>
            <h3 className='suborder-title'>Pedido: {sub_order.sub_order_code}</h3>
            <div className='card-suborder__actions'>
              {
                // Botão só aparece quando ainda não cancelado
                status.code !== 'C' && status.code !== 'CBS' && status.code !== 'CES' && (
                  <PermissionsChecker permissions={['cancel_order']}>
                    <div className="card-student-detail__sub_order_cancel">
                      <a href="#" onClick={e => e.preventDefault() & cancelSubOrder(sub_order.id)}>Cancelar pedido</a>
                    </div>
                  </PermissionsChecker>
                )
              }
              {status.code === 'AP' || status.code === 'PG' || status.code === 'PAV' ? (
                <span className="card-student-detail__column-printer-text" onClick={() => this._printHandler()}>Comprovante</span>
              ) : null}
            </div>
          </div>
          <div className='card-suborder__body'>
            <div className='card-suborder-info'>
              <p><strong>Tipo de produto: </strong> {sub_order.business_line_name}</p>
              <p><strong>Aluno: </strong> {sub_order.student_name}</p>
              {this._isServiceLine(sub_order) ?
                <p><strong>Entrega: </strong> Após confirmação de pagamento, a licença será enviada para o e-mail que realizou a compra.</p>
                : this._isServiceLine(sub_order) ? '' :
                  <p><strong>Entrega: </strong> {this._getOrderDeliveryDestination()}</p>}
            </div>

          </div>
          <TrackingTimeline subOrder={sub_order} order={order} />

          {
            sub_order.business_line_id === 7 || !sub_order.school_is_b2c ? '' : <div className='card-suborder__footer' style={{ borderTop: !sub_order.history.some(history => history.status === 'PF') && 'none' }}>
              <div className='tracking-info'>
                {sub_order.history.some(history => history.status === 'PF') ? <p className='tracking-code'><strong>Nota fiscal: {sub_order?.nf_number || '##########'} </strong> </p> : ''}
                {sub_order.history.some(history => history.status === 'PT') ? <p><a target='_blank' href={this._formatOldTrackingLink(sub_order.tracking_link) || '#'}>Clique aqui para acompanhar a entrega</a></p> : ''}
              </div>
            </div>
          }

          <div className={`card-student-detail__switch-book-view ${show}`}>
            <button onClick={() => this.setState({ opened: !this.state.opened })}>
              <span className="label">{this.state.opened ? 'Ocultar detalhes' : 'Ver detalhes'}</span>
            </button>
          </div>
          {this.state.opened ? children : null}
        </div>
      </>
    )
  }
}

export default CardSubOrder
