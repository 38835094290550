import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { approveOrder, cancelOrder, cancelOrderItem } from '@services/sm-api'
import { closeLoader, openLoader } from '@actions/loader'
import { closeModal, openModal } from '@actions/modal'
import CardRequestDetail from '@components/CardRequestDetail'
import CardStatus from '@components/CardStatus'
import CardStudent from '@components/CardStudent'
import CardSubOrder from '@components/CardSubOrder'
import ConfirmDialog from '@shared/ConfirmDialog'
import PermissionsChecker from '@shared/PermissionsChecker'
import ProductsTable from '@components/ProductsTable'
import boleto from './images/001-bar-code.png'
import boleto_hibrido from './images/boleto-hibrido.png'
import pix from './images/pix.png'
import credit from './images/credito.png'
import twoCards from './images/two-cards.png'
import { getOrderDetail } from '@actions/order'
import { formatMoney } from '@shared/FormatMoney'
import { listVariables } from '@services/sm-api'
import { paymentStatus } from '@utils/paymentStatus'
import { orderStatus as OS } from '../../utils/orderStatus'
import { withRouter } from '../../utils/withRouter'
import TrackingTimeline from '../TrackingTimeline'
import FileDownloader from '../../shared/FileDownloader'
import { blobDownload } from '@services/sm-api.js'

export class OrderDetail extends Component {

  count;
  state = {}
  initialState = {
    orderStatus: '',
    subOrdersStatus: {},
    payment: {
      R: { icon: credit, name: 'Cartão de crédito', machineName: 'credit_card' },
      B: { icon: boleto, name: 'Boleto', machineName: 'billet' },
      H: { icon: boleto_hibrido, name: 'Boleto/PIX', machineName: 'billet' },
      P: { icon: pix, name: 'PIX', machineName: 'pix' },
      undefined: { icon: credit, name: 'Cartão de crédito' },
    },
    licenseActivationLink: null,
    hybridBilletActivation: null,
  }

  constructor(props) {
    super(props)
    this.state = { ...this.initialState }
  }


 

  componentDidMount() {
    listVariables().then(variables => {
      this.setState({
        licenseActivationLink: _.find(variables, { key: 'LICENSE_ACTIVATION_LINK' }).value,
        hybridBilletActivation: _.find(variables, { key: 'HYBRID_BILLET_ACTIVATION' }).value,
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.order !== this.props.order) {
      this.checkIfPaymentWasWithTwoCards(this.props.order);
    }
  }

  UNSAFE_componentWillMount() {
    this._handleCancelOrder = this._handleCancelOrder.bind(this)
    this.props.getOrderDetail()
    if (this.props.order) {
      this._handleSetOrderStatus()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._handleSetOrderStatus()
    this._initSubOrdersStatus(nextProps)
  }

  _handleSetOrderStatus() {
    const validStatus =
      typeof this.props.order?.order_status === 'string'
        ? this.props.order.order_status
        : typeof this.props.order?.status === 'string'
          ? this.props.order.status
          : 'null'

    this._setOrderStatus(validStatus)
  }

  _initSubOrdersStatus(nextProps) {
    let {
      order: { sub_orders = {} },
    } = nextProps
    let subOrdersStatus = {}

    for (let student of Object.keys(sub_orders)) {
      for (let sub_order of sub_orders[student]) {
        subOrdersStatus[sub_order.id] = sub_order.status
      }
    }

    this.setState({
      subOrdersStatus: subOrdersStatus,
    })
  }

  _setOrderStatus(orderStatus) {
    if (orderStatus) {
      this.setState({
        orderStatus: orderStatus,
      })
    }
  }

  _setSubOrderStatus(subOrderId, subOrderStatus) {
    if (subOrderStatus) {
      let subOrdersStatus = this.state.subOrdersStatus
      if (subOrderId === 'all') {
        for (let key of Object.keys(subOrdersStatus)) {
          subOrdersStatus[key] = subOrderStatus
        }
      } else {
        subOrdersStatus[subOrderId] = subOrderStatus
      }
      this.setState({ subOrdersStatus: subOrdersStatus })
    }
  }

  _getSubOrderStatus(subOrderId, isB2C) {
    var sub_order_status = this.state.subOrdersStatus[subOrderId]
    if (isB2C == 0 && (sub_order_status == 'PP' || sub_order_status == 'PF' || sub_order_status == 'PT' || sub_order_status == 'PET' || sub_order_status == 'PE')) {
      sub_order_status = 'AP'
    }
    return {
      sub_order_id: subOrderId,
      code: sub_order_status,
      ...paymentStatus[sub_order_status],
    }
  }

  _getOrderMethod(subOrderId) {
    let method = ''
    let { order } = this.props
    let type = this.state.payment[order.payment_type].machineName

    if (
      this.state.orderStatus === 'BAP' ||
      this.state.orderStatus === 'NP' ||
      (this.state.orderStatus === 'AP' && order.payment_type === 'R') ||
      (this.state.orderStatus === 'AP' && order.payment_type === 'B')
    ) {
      method = 'internal'
    } else {
      method = 'external'
    }

    let data = {
      order_id: order.order_id,
      type,
      method,
    }
    if (subOrderId) {
      data.sub_order_id = subOrderId
      data.method = 'internal'
    }
    return data
  }

  _paiedModal() {
    const { openModal, closeModal } = this.props
    openModal(
      <ConfirmDialog
        text="Boleto já foi pago e não será possível cancela-lo."
        okLabel="OK"
        ok={() => {
          closeModal()
        }}
      />,
    )
  }


  _handleCancelOrder(subOrderId) {
    const { openLoader, closeLoader, openModal, closeModal } = this.props
    let data = this._getOrderMethod(subOrderId)

    openModal(
      <ConfirmDialog
        text="Tem certeza que deseja cancelar esse pedido?"
        ok={() => {
          closeModal()
          openLoader()

          cancelOrder(data)
            .then(response => {
              if (response.status === 200) {
                if (subOrderId) {
                  this._setSubOrderStatus(subOrderId, response.type)
                } else {
                  this.setState({ orderStatus: response.type })
                  this._setSubOrderStatus('all', response.type)
                }
                if (response.billet_paid) {
                  this._paiedModal()
                }
              }
            })
            .finally(() => {
              closeLoader()
            })
        }}
        cancel={() => closeModal()}
      />,
    )
  }

  _handleCancelOrderItem(order_item_id) {
    const { openLoader, closeLoader } = this.props
    openLoader()

    return cancelOrderItem(order_item_id)
      .then(() => this.props.getOrderDetail())
      .finally(() => {
        closeLoader()
      })
  }

  _handleApproveOrder(subOrderId) {
    const { openLoader, closeLoader } = this.props
    let data = this._getOrderMethod(subOrderId)
    openLoader()

    approveOrder(data)
      .then(response => {
        if (response.status === 200) {
          if (subOrderId) {
            this._setSubOrderStatus(subOrderId, response.type)
          } else {
            this.setState({ orderStatus: response.type })
            this._setSubOrderStatus('all', response.type)
          }
        }
      })
      .finally(() => {
        closeLoader()
      })
  }

  _checkDigitalCode = sub_order => sub_order.products.some(product => product.bookLicenses.length > 0)

  _calculateTotal(products) {
    let total = 0
    products.forEach(product => (total += parseFloat(product.amount_discounted)))
    return total
  }

  _getPaymentType(order) {
    let payment_type = order.payment_type
    if (order.payment_type === 'B') {
      if (order.status === 'PG' || order.status === 'AP' || order.status === 'PAV') {
        if (order.txid) {
          payment_type = 'P'
        }
      } else if (this.state.hybridBilletActivation == 1) {
        payment_type = 'H'
      }
    }
    return payment_type
  }

  _handleExportOrder() {
    this.props.openLoader()
    let url = `/orders/${this.props.order.order_id}/export`
    return blobDownload(url, {}).finally(() => this.props.closeLoader())
  }

  checkIfPaymentWasWithTwoCards(currentOrder) {

    const order = currentOrder;
    
    if (order.is_two_cards === 1) {

      const { sub_orders } = order;
      const subOrdersToList = Object.keys(sub_orders).map(key => sub_orders[key]);

      subOrdersToList[0][0].total = _.sumBy(subOrdersToList[0].map(x => ({ ...x, amount: parseFloat(x.amount) })), 'amount');
      subOrdersToList[1][0].total = _.sumBy(subOrdersToList[1].map(x => ({ ...x, amount: parseFloat(x.amount) })), 'amount');

      const cards = [subOrdersToList[0][0], subOrdersToList[1][0]];
      
      const freight = parseFloat(order.freight.replace('R$', '').replace(',', '.'));
      cards[0].total = cards[0].total + freight;
      
      this.setState({ payment: { R: { icon: twoCards, name: 'Dois cartões', machineName: 'credit_card', cards: cards }, } })
    
    }

  }

  render() {
    if (typeof this.props.order !== 'undefined') {
      const {
        order,
        order: { sub_orders = [] },
      } = this.props
      let _payment_type = this._getPaymentType(order)
      let showDue = order.payment_type === 'B' ? 'Vencimento: ' + order.billet_date_due : null

      return (
        <div className="order-detail">
          <div className="order-detail__header header_buttons">
            <h1 className="order-detail__title">Pedido SMD-{order.order_id}</h1>
            <div className='buttons_action'>
              {
                // Botão só aparece quando ainda não cancelado
                this.state.orderStatus !== 'C' &&
                this.state.orderStatus !== 'CM' &&
                this.state.orderStatus !== 'CBS' &&
                this.state.orderStatus !== 'CES' && (
                  <PermissionsChecker permissions={['cancel_order']}>
                    <button
                      className="button button__small bg-lightgray order-detail__cancelbutton"
                      onClick={() => {
                        this._handleCancelOrder()
                      }}
                    >
                      {'cancelar pedido'}
                    </button>
                  </PermissionsChecker>
                )
              }
              {
                // Botão só aparece quando valor pago é divergente e com boleto
                this.state.orderStatus === 'DV' && this.props.order.payment_type === 'B' && (
                  <PermissionsChecker permissions={['cancel_order']}>
                    <button
                      className="button button__small bg-lightgray order-detail__approvebutton"
                      onClick={() => {
                        this._handleApproveOrder()
                      }}
                    >
                      {'aprovar pedido'}
                    </button>
                  </PermissionsChecker>
                )
              }
              <PermissionsChecker permissions={['export_order_data']}>
                <FileDownloader
                  onClick={() =>
                    this._handleExportOrder()
                  }
                  filename={`order-${this.props.order.order_id}.xls`}>
                  <button className="button button__small bg-lightgray order-detail__cancelbutton"> {'Exportar dados'} </button>
                </FileDownloader>
              </PermissionsChecker>
            </div>
          </div>
          <CardRequestDetail
            payment={this.state.payment[_payment_type]}
            buyer_name={order.buyer_name}
            created={order.created}
            due={showDue}
            icon={this.state.payment[_payment_type].icon}
            subtotal={order.subtotal}
            discounted={order.order_discount}
            extra_discount={order.extra_discount}
            total={order.total}
            prev_total={order.prev_total}
            prev_subtotal={order.prev_subtotal}
            status={<CardStatus {...OS[this.state.orderStatus]} />}
            installmentString={order.installment_string}
            paymentType={_payment_type}
            paymentCondition={order.payment_condition}
            freight={order.freight}
            discount={order.discount}
            orderStatus={this.state.orderStatus}
            billetUrl={order.billet_url}
            onCancelOrder={() => this._handleCancelOrder()}
            pix={{
              order_id: order.order_id,
              pix_copy_paste: order.pix_copy_paste,
              pix_qrcode: order.pix_qrcode,
              pix_expiration: order.pix_expiration,
            }}
          />

          {/* <TrackingTimeline history={this.props.order.history} /> */}

          <div className="card-student-detail__wrapper">
            {Object.keys(sub_orders).map(student => {
              return (
                <CardStudent
                  student={sub_orders[student][0].student_name}
                  school_name={sub_orders[student][0].school_name}
                  voucher_name={sub_orders[student][0].voucher_name}
                  key={sub_orders[student][0].id}
                >
                  {sub_orders[student].map((sub_order, index) => {
                    return (
                      <CardSubOrder
                        order={order}
                        sub_order={sub_order}
                        status={this._getSubOrderStatus(sub_order.id, sub_order.school_is_b2c)}
                        buyer_name={order.buyer_name}
                        key={index}
                        openModal={this.props.openModal}
                        closeModal={this.props.closeModal}
                        cancelSubOrder={this._handleCancelOrder}
                      >
                        <div className="order-detail__wrapper">
                          <h4 className="order-detail__table-title">Produtos selecionados:</h4>
                          <ProductsTable
                            products={sub_order.products}
                            header={[
                              {
                                property: 'images',
                                alias: '',
                                key: 'GraficoSmall',
                                type: 'image',
                              },
                              {
                                property: 'product_name',
                                alias: 'Nome',
                                type: 'name',
                              },
                              {
                                property: 'theme',
                                alias: 'Matéria',
                                type: 'default',
                              },
                              {
                                property: 'serie_name',
                                alias: 'Ano',
                                type: 'default',
                              },
                              {
                                property: 'amount_discounted',
                                alias: 'Total',
                                type: 'currency',
                              },
                              {
                                property: 'cancelled_at',
                                alias: '',
                                type: 'default',
                              },
                            ]}
                            fixedCol={{ serie_name: sub_order.serie_name }}
                            handleCancelOrderItem={this._handleCancelOrderItem.bind(this)}
                          />
                          <div className="order-detail__total">
                            <span className="order-detail__text">Total</span>
                            <span className="order-detail__value">
                              {formatMoney(this._calculateTotal(sub_order.products), 2, 2)}
                            </span>
                          </div>
                          {this.state.licenseActivationLink && this._checkDigitalCode(sub_order) && (
                            <div className="order-detail__license_activation_link">
                              <p>
                                Ativação das licenças na plataforma <b>{sub_order.digital_solution || ''}</b>
                              </p>
                              <p>
                                <a target="_blank" href={this.state.licenseActivationLink}>
                                  Clique aqui para acessar o tutorial de ativação.
                                </a>
                              </p>
                            </div>
                          )}
                        </div>
                      </CardSubOrder>
                    )
                  })}
                  <div className='alert-card-student-orders'>
                    <span className="fa fa-warning"></span> &nbsp;&nbsp;
                    Devido ao grande volume de pedidos, alguns subpedidos podem acabar sendo entregues em momentos diferentes. Os status estarão sendo atualizados diariamente nos detalhes do pedido.</div>
                </CardStudent>

              )
            })}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state, props) => ({
  order: state.order.active,
  params: props.params,
  sessionReducer: state.sessionReducer,
})

const mapDispatchToProps = dispatch => ({
  getOrderDetail: params => dispatch(getOrderDetail(params)),
  openModal: component => dispatch(openModal(component)),
  closeModal: () => dispatch(closeModal()),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
})

const mergeProps = (stateProps, dispatchProps, props) => ({
  order: stateProps.order,
  params: props.params,
  getOrderDetail: () => {
    let params = {
      group: 'voucher',
      order_id: props.params.order_id,
    }
    return dispatchProps.getOrderDetail(params)
  },
  closeModal: dispatchProps.closeModal,
  openModal: component => dispatchProps.openModal(component),
  openLoader: dispatchProps.openLoader,
  closeLoader: dispatchProps.closeLoader,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(OrderDetail))